import * as React from "react"
import { Link } from "gatsby"
import ContactForm from "../components/Home/Contact/ContactForm"
import Layout from "../components/layout"
import MastheadImage from "../images/actionera-mockup.png"
import Screenshot1 from "../images/actionera-screenshot-1.png"
import Screenshot2 from "../images/actionera-screenshot-2.png"
import OtherWork2 from "../images/mockup-smartpro.png"
import OtherWork1 from "../images/qoi-mockup.png"
import "./page.css"

const WorkActionEra = () => {

  return (
    <div>
      <Layout showHeader selectedHeader={`OurWorks`}>
        <section id="header" className="our-works-content min-h-screen flex flex-col">
          <div className="container mx-auto pt-20 pb-10 px-8 lg:px-0 lg:pb-12">
            <h1 className="heading uppercase lg:font-semibold text-dark-grey text-4xl md:text-7xl">
              Our Works
            </h1>
          </div>
          <div className="work-preview w-full">
            <div className="mobile-wrap" style={{ backgroundImage: 'url(' + MastheadImage + ')'}}>
              <img src={MastheadImage} alt="Preview of Actionera"/>
            </div>
          </div>
          <div className="w-full bg-white">

            <div className="container mx-auto w-full px-8 py-8 lg:px-0 lg:pt-12 pb-18">
              <div className="block lg:flex lg:justify-between">
                <div className="works-title text-6xl font-bold lg:w-1/2 text-[#707070]">
                Actionera
                </div>
                <div>
                  <div class="works-type-gradient inline-block rounded-full p-4 mx-2 text-white">
                    Software Engineering
                  </div>
                  <div class="works-type-gradient  inline-block rounded-full p-4 mx-2 text-white">
                    UI/UX Design
                  </div>

                </div>

              </div>     
              <div className="work-body py-8">
              Formerly called Expert App, Actionera is a content, membership, event, and course platform that gives entrepreneurs, coaches, speakers, or any content creator a medium to share their expertise and passion. This enables them to share their knowledge more freely and have the added credibility of having their own app without having to worry about the coding or programming end.
              </div>
              <div className="flex justify-center">
                <img src={Screenshot1} alt="Screenshot of Actionera"/>
              </div>
              <div className="work-body py-8">
              TechTank was tasked to migrate and revamp their creator portal’s API from the ground-up so that Actionera can scale and have flexibility in the features they want to offer. This means having to improve and simplify workflows within the portal. A content creator, who would also be the app owner, is able to input, manage, and release content in the portal seamlessly and have it pushed in their app real time for all their users and members to see.
              </div>
              <div className="flex justify-center">
                <img src={Screenshot2} alt="Screenshot of Actionera"/>
              </div>
              <div className="work-body py-8">
              The TechTank team uses Agile Methodology, in order to efficiently test and deploy new features as they become ready.
              <br/><br/>
              The client has been happy with the quality of the output and has also entrusted TechTank with the project of developing the web version of the app. 
              </div>
              <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start">



              </div>
            </div>

          </div>
        </section>

        <div className="other-works-container w-full">
          <section id="other-works" className="container mx-auto w-full px-8 py-8 lg:px-0 lg:pt-12 pb-32">
            <h3 className="heading text-5xl py-8">View more works</h3>
            <div className="other-work-cards flex gap-4 content-center">
              <Link to="/work-qoi" className="other-work text-center" style={{backgroundImage: 'url(' + OtherWork1 + ')'}}>
                  <span>QOI SYSTEMS INTEGRATION</span>
              </Link>
              <Link to="/work-smartpro" className="other-work px-8 text-center" style={{backgroundImage: 'url(' + OtherWork2 + ')'}}>
                  <span>SMART-PRO CLEANERS</span>
              </Link>
            </div>
          </section>
        </div>

        <ContactForm />
      </Layout>
    </div>
  )
}

export default WorkActionEra
